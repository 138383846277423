import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'scelta-cotta',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'scelta-cotta',
    loadChildren: () => import('./scelta-cotta/scelta-cotta.module').then( m => m.SceltaCottaPageModule)
  },
  {
    path: 'cotta-details',
    loadChildren: () => import('./cotta-details/cotta-details.module').then( m => m.CottaDetailsPageModule)
  },
  {
    path: 'invitelist',
    loadChildren: () => import('./invitelist/invitelist.module').then( m => m.InvitelistPageModule)
  },
  {
    path: 'invito/:id',
    loadChildren: () => import('./invito/invito.module').then( m => m.InvitoPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
